//types variables
import {darken, lighten} from "polished";

export const headings_font_family = "inherit";
export const headings_font_weight = 500;
export const headings_line_height = 1.1;
export const headings_color = "inherit";
export const screen_min_sm = "768px";

//base font sizes
export const font_size_base = "1.6rem";
export const font_size_small = "1.4rem";
export const blockquote_font_size = "2rem";

// headings sizes
export const font_size_h1 = "2rem";
export const font_size_h2 = "1.8rem";
export const font_size_h3 = "1.7rem";
export const font_size_h4 = font_size_base;
export const font_size_h5 = font_size_base;
export const font_size_h6 = font_size_base;

//base colors
export const gray_light = lighten(80, "#000");
export const gray_lighter = lighten(86.5, "#000");
export const body_bg = "#fff";
export const link_color = "#0084e0";
export const link_hover_color = darken(15, link_color);
export const link_hover_decoration = "underline";
export const text_color = "#333";
export const brand_primary = "#0084e0";
export const blockquote_border_color = gray_lighter;
export const blockquote_small_color = gray_light;

//colors
export const headings_small_color = gray_light;
export const text_muted = gray_light;
export const state_success_text = "#3c763d";
export const state_info_text = "#31708f";
export const state_warning_text = "#8a6d3b";
export const state_danger_text = "#a94442";
export const state_success_bg = "#dff0d8";
export const state_info_bg = "#d9edf7";
export const state_warning_bg = "#fcf8e3";
export const state_danger_bg = "#f2dede";
export const page_header_border_color = gray_lighter;

//line heights
export const line_height_computed = 20;
export const line_height_base = 1.428571429;

//others
export const dl_horizontal_breakpoint = 0;
export const dl_horizontal_offset = "768px";
export const border_radius_large = "5px";
export const thumbnail_padding = ".4rem";
export const thumbnail_bg = "#f2f2f2";
export const thumbnail_border = "#ddd";
export const thumbnail_border_radius = "4px";
export const hr_border = gray_lighter;
