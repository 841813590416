import styled from "@emotion/styled";

import {psa} from "../../styles/helpers";

export const Title = styled("h3")`
    margin: 0 0 30px 0;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0.67px;

    @media (max-width: 1700px) {
        font-size: 35px;
    }

    @media (max-width: 1350px) {
        font-size: 30px;
        margin-bottom: 20px;
    }
`;

export const SubTitle = styled("h4")`
    font-weight: 300;
    font-size: 30px;
    letter-spacing: 0.5px;
    margin: 0 0 20px 0;

    @media (max-width: 1700px) {
        font-size: 25px;
    }

    @media (max-width: 1350px) {
        font-size: 20px;
    }
`;

export const Button = styled("button")`
    border: none;
    border-radius: 5px;
    background: linear-gradient(244.81deg, #5a054e 0%, #ff4755 98.59%, #001349 100%);
    padding: 10px 30px;
    height: 45px;
`;

export const DownloadButton = styled(Button)`
    ${psa};
    height: 78px;
    width: 190px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -30px;
    text-transform: uppercase;
    color: #fff;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 0.58px;
    line-height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    zoom: 0.8;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: #fff;
    }

    @media screen and (max-width: 1280px) {
        height: 50px;
        width: 150px;
        font-size: 20px;
    }
`.withComponent("a");
