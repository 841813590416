/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */

declare global {
    interface Window {
        dataLayer: object[];
    }
}

let isGtmInitialized = false;

export const initGtagGoogleTagManager = (gtmId: string): void => {
    // initialize only once
    if (isGtmInitialized) {
        return;
    }
    // fetch/init gtag
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    }

    (gtag as any)("js", new Date());
    (gtag as any)("config", gtmId);

    const firstScriptElem = document.getElementsByTagName("script")[0];

    if (firstScriptElem.parentNode == null) {
        throw new Error("init_gtag_google_tag_manager: no first script element's parent node found");
    }

    const newScriptElem = document.createElement("script");

    newScriptElem.async = true;
    newScriptElem.src = "https://www.googletagmanager.com/gtag/js?id=" + gtmId;
    firstScriptElem.parentNode.insertBefore(newScriptElem, firstScriptElem);
    // initialize only once
    isGtmInitialized = true;
};
