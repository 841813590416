import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {IIssue} from "../api/get_issues";
import {df, fai_center, fd_col, fjc_sa} from "../styles/flexbox";
import {m_0a, ph_xl, psr, tdn} from "../styles/helpers";
import {Checklist} from "./common/Checklist";
import {DownloadButton} from "./common/commonStyledElements";
import {SectionTitle} from "./common/SectionTitle";
import {FacebookIcon} from "./icons/FacebookIcon";

interface IProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
    issue: IIssue;
}

export const LatestEdition = (props: IProps) => {
    return (
        <Container ref={props.refElem}>
            <SectionTitle title="Najnowszy numer" />

            <ContentContainer>
                <ImgHolder>
                    <EditionImg src={props.issue.cover_image} />
                    <DownloadButton href={props.issue.file} target="_blank">
                        Pobierz
                    </DownloadButton>
                </ImgHolder>

                <div>
                    <Checklist items={props.issue.headlines} isDark />

                    <FbHolder href="https://www.facebook.com/groups/deweloper.i.marketing/" target="_blank">
                        <FbIcon width={70} height={70} />

                        <p>
                            Chcesz wiedzieć więcej o marketingu w branży nieruchomości?
                            <br />
                            Zapisz się do naszej grupy na Facebooku{" "}
                            <span
                                css={css`
                                    color: #ec4053;
                                `}
                            >
                                „Deweloper&Marketing”
                            </span>
                            !
                        </p>
                    </FbHolder>
                </div>

                <FbHolderMobile href="https://www.facebook.com/groups/deweloper.i.marketing/" target="_blank">
                    <p>
                        Chcesz wiedzieć więcej o marketingu w branży nieruchomości? Zapisz się do naszej grupy na Facebooku
                        <span
                            css={css`
                                color: #ec4053;
                            `}
                        >
                            {" "}
                            „Deweloper&Marketing”
                        </span>
                        !
                    </p>
                </FbHolderMobile>
            </ContentContainer>
        </Container>
    );
};

const Container = styled("section")`
    margin-top: 30px;
    margin-bottom: 30px;
`;

const ImgHolder = styled("div")`
    ${psr};
    padding-right: 20px;

    @media (max-width: 1025px) {
        padding-right: 0;
        margin-top: 20px;
        order: 2;
    }
`;

const EditionImg = styled("img")`
    width: 346px;
    height: 472px;
    @media (max-width: 1025px) {
        width: 280px;
        height: 413px;
    }
`;

const ContentContainer = styled("div")`
    ${m_0a};
    ${ph_xl};
    ${df};
    ${fjc_sa};
    ${fai_center};
    max-width: 1400px;

    @media (max-width: 1025px) {
        ${fd_col}
    }
`;

const FbHolder = styled("a")`
    ${df};
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 20px;
    font-size: 25px;
    font-weight: 300;
    color: #0c284f;
    margin-top: 40px;
    &:hover {
        ${tdn}
    }

    @media (max-width: 1025px) {
        display: none;
    }
`;

const FbHolderMobile = styled("a")`
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px 20px;
    font-size: 20px;
    font-weight: 300;
    color: #0c284f;
    margin-top: 50px;
    order: 3;
    &:hover {
        ${tdn}
    }

    @media (min-width: 1025px) {
        display: none;
    }
`;

const FbIcon = styled(FacebookIcon)`
    margin-right: 20px;
`;
