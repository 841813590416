import {css} from "@emotion/react";

import {normalize} from "./normalize";
import {scaffolding} from "./scaffolding";
import {types} from "./types";

export const global_styles = css`
    ${normalize};
    ${types};
    ${scaffolding};
`;
