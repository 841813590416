import {css} from "@emotion/react";

import {
    body_bg,
    border_radius_large,
    font_size_base,
    hr_border,
    line_height_base,
    line_height_computed,
    link_color,
    link_hover_color,
    link_hover_decoration,
    text_color,
    thumbnail_bg,
    thumbnail_border,
    thumbnail_border_radius,
    thumbnail_padding
} from "./variables";

export const scaffolding = css`
    //
    // Scaffolding
    // --------------------------------------------------

    // Reset the box-sizing
    //
    // Heads up! This reset may cause conflicts with some third-party widgets.
    // For recommendations on resolving such conflicts, see
    // https://getbootstrap.com/docs/3.4/getting-started/#third-box-sizing
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    *:before,
    *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    // Body reset

    html {
        font-size: 10px !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    body {
        font-size: ${font_size_base};
        line-height: ${line_height_base};
        color: ${text_color};
        background-color: ${body_bg};
    }

    // Reset fonts for relevant elements
    input,
    button,
    select,
    textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    // Links

    a {
        color: ${link_color};
        text-decoration: none;

        &:hover,
        &:focus {
            color: ${link_hover_color};
            text-decoration: ${link_hover_decoration};
        }

        &:focus {
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }
    }

    // Figures
    //
    // We reset this here because previously Normalize had no \`figure\` margins. This
    // ensures we don't break anyone's use of the element.

    figure {
        margin: 0;
    }

    // Images

    img {
        vertical-align: middle;
    }

    // Responsive images (ensure images don't scale beyond their parents)
    .img-responsive {
        display: block;
        max-width: 100%; // Part 1: Set a maximum relative to the parent
        height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
    }

    // Rounded corners
    .img-rounded {
        border-radius: ${border_radius_large};
    }

    // Image thumbnails
    //
    // Heads up! This is mixin-ed into thumbnails.less for \`.thumbnail\`.
    .img-thumbnail {
        padding: ${thumbnail_padding};
        line-height: ${line_height_base};
        background-color: ${thumbnail_bg};
        border: 1px solid ${thumbnail_border};
        border-radius: ${thumbnail_border_radius};

        // Keep them at most 100% wide
        display: block;
        max-width: 100%; // Part 1: Set a maximum relative to the parent
        height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
    }

    // Perfect circle
    .img-circle {
        border-radius: 50%; // set radius in percents
    }

    // Horizontal rules

    hr {
        margin-top: ${line_height_computed};
        margin-bottom: ${line_height_computed};
        border: 0;
        border-top: 1px solid ${hr_border};
    }

    // Only display content to screen readers
    //
    // See: https://a11yproject.com/posts/how-to-hide-content

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    // Use in conjunction with .sr-only to only display content when it's focused.
    // Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
    // Credit: HTML5 Boilerplate

    .sr-only-focusable {
        &:active,
        &:focus {
            position: static;
            width: auto;
            height: auto;
            margin: 0;
            overflow: visible;
            clip: auto;
        }
    }

    // iOS "clickable elements" fix for role="button"
    //
    // Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
    // for traditionally non-focusable elements with role="button"
    // see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

    [role="button"] {
        cursor: pointer;
    }
`;
