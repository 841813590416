import React from "react";
import {FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import styled from "@emotion/styled";

interface IProps {
    img: string;
    title: string;
    lead: string;
    articleLink: string;
    breakpoint?: number;
}

export const ArticleBox = (props: IProps) => {
    const truncateString = (text: string, count: number) => {
        if (text.length > count) {
            return text.slice(0, count) + "...";
        }
        return text;
    };

    return (
        <BoxHolder breakpoint={props.breakpoint}>
            <BoxLink href={props.articleLink} target="_blank">
                <BoxImg src={props.img} alt={props.title} breakpoint={props.breakpoint} />

                <ContentHolder>
                    <TitleWrapper>
                        <Title breakpoint={props.breakpoint}>{truncateString(props.title, 50)}</Title>
                    </TitleWrapper>

                    <LeadWrapper>
                        <Lead breakpoint={props.breakpoint}>{truncateString(props.lead, 190)}</Lead>
                    </LeadWrapper>

                    <SocialHolder>
                        <FacebookShareButton url={props.articleLink}>
                            <FacebookIcon size={30} round path="" />
                        </FacebookShareButton>

                        <TwitterShareButton url={props.articleLink}>
                            <TwitterIcon size={30} round path="" />
                        </TwitterShareButton>

                        <LinkedinShareButton url={props.articleLink}>
                            <LinkedinIcon size={30} round path="" />
                        </LinkedinShareButton>
                    </SocialHolder>
                </ContentHolder>
            </BoxLink>
        </BoxHolder>
    );
};

const BoxHolder = styled("div")`
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    max-width: 375px;
    @media (max-width: ${(props: {breakpoint?: number}) => (props.breakpoint ? props.breakpoint : 1250)}px) {
        max-width: 300px;
    }
`;

const BoxImg = styled("img")`
    width: 375px;
    height: 320px;
    @media (max-width: ${(props: {breakpoint?: number}) => (props.breakpoint ? props.breakpoint : 1250)}px) {
        width: 300px;
        height: 240px;
    }
`;

const BoxLink = styled("a")`
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: none;
    }
`;
``;
const ContentHolder = styled("div")`
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TitleWrapper = styled("div")`
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
`;

const Title = styled("h3")`
    margin: 0;
    color: #0c284f;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: center;
    padding: 0;
    @media (max-width: ${(props: {breakpoint?: number}) => (props.breakpoint ? props.breakpoint : 1250)}px) {
        font-size: 24px;
    }
`;

const LeadWrapper = styled("div")`
    height: 150px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const Lead = styled("p")`
    margin: 0;
    padding: 0;
    color: #757575;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.33px;
    line-height: 30px;
    text-align: center;
    @media (max-width: ${(props: {breakpoint?: number}) => (props.breakpoint ? props.breakpoint : 1250)}px) {
        font-size: 16px;
    }
`;

const SocialHolder = styled("div")`
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
