import React from "react";
import styled from "@emotion/styled";

import {IIssue} from "../api/get_issues";
import {SectionTitle} from "./common/SectionTitle";

interface IProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
    issue: IIssue | null;
}

export const AboutIssue = (props: IProps) => {
    return (
        <AboutIssueSection ref={props.refElem}>
            <SectionTitle title="O Kwartalniku" />

            <Container>
                <ContentContainer>
                    {props.issue && (
                        <>
                            <DesktopIssueImage src={props.issue.main_page_image} />

                            <MobileIssueImage src={props.issue.main_page_image} width="100%" height="auto" alt={props.issue.number} />
                        </>
                    )}

                    <TextContainer>
                        <ParagraphGray>
                            Nasze czasopismo tworzą eksperci portalu RynekPierwotny.pl, którzy pomagają prawie 1000 firm deweloperskich wypromować inwestycje
                            mieszkaniowe.
                        </ParagraphGray>

                        <NavyDashedBox>
                            Jeśli jesteś zainteresowany współpracą z nami lub reklamą w kwartalniku napisz:
                            <a href="mailto:pr@rynekpierwotny.pl">pr@rynekpierwotny.pl</a>
                        </NavyDashedBox>
                    </TextContainer>
                </ContentContainer>
            </Container>
        </AboutIssueSection>
    );
};

const AboutIssueSection = styled("section")`
    margin-top: 10px;

    @media screen and (min-width: 1024px) {
        position: relative;
        margin-top: 75px;
        box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
    }
`;

const Container = styled("div")`
    @media screen and (min-width: 1024px) {
        height: 300px;
        max-width: 1400px;
        margin: 0 auto;
    }

    @media screen and (min-width: 1280px) {
        height: 350px;
    }

    @media screen and (min-width: 1600px) {
        height: 415px;
    }
`;

const DesktopIssueImage = styled("img")`
    display: none;
    position: absolute;
    right: 0;
    top: 72px;
    max-width: 691px;
    clip-path: polygon(0 0, 100% 1%, 100% 100%, 17% 100%);

    @media screen and (min-width: 1024px) {
        display: block;
        max-height: 298px;
    }

    @media screen and (min-width: 1280px) {
        display: block;
        max-height: 338px;
    }

    @media screen and (min-width: 1600px) {
        display: block;
        max-height: 380px;
    }
`;

const MobileIssueImage = styled("img")`
    height: auto;
    width: 100%;
    margin: 10px auto;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const ContentContainer = styled("div")`
    max-width: 1400px;
    margin: 30px auto;
    padding: 0 20px;
`;

const TextContainer = styled("div")`
    width: 100%;

    @media screen and (min-width: 1024px) {
        width: 50%;
    }
`;

const ParagraphGray = styled("p")`
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    color: #757575;

    @media screen and (min-width: 1024px) {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    @media screen and (min-width: 1280px) {
        font-size: 26px;
        line-height: 35px;
        margin-bottom: 30px;
    }

    @media screen and (min-width: 1600px) {
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 30px;
    }
`;

const NavyDashedBox = styled("div")`
    width: 100%;
    height: auto;
    border: 4px dashed #002664;
    color: #0c284f;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 17px;

    @media screen and (min-width: 1024px) {
        padding: 30px 35px;
        font-size: 25px;
        line-height: 40px;
    }

    @media screen and (min-width: 1280px) {
        padding: 30px 50px;
        font-size: 30px;
        line-height: 45px;
    }

    @media screen and (min-width: 1600px) {
        height: 215px;
        width: 748px;
        padding: 30px 100px;
        font-size: 30px;
        line-height: 45px;
    }

    a {
        color: #ec4053;
        font-weight: 500;
        font-size: 22px;
        text-decoration: none;

        @media screen and (min-width: 1024px) {
            font-size: 35px;
        }
    }
`;
