import React from "react";
import styled from "@emotion/styled";

import {IPartner} from "../api/get_partners";
import {df, fai_center, fd_col, fjc_center} from "../styles/flexbox";
import {bgc_white, c_black, c_white, dn, fs16, h100, m_0a, ph_xl, psa, psr, w100, z1, z2} from "../styles/helpers";
import {Checklist} from "./common/Checklist";
import {Title} from "./common/commonStyledElements";

import hero from "../images/hero.jpg";

const HERO_CHECKLIST = [
    "Inspirujące wywiady z gośćmi specjalnymi",
    "Najświeższe trendy marketingowe w branży nieruchomości",
    "Case studies SEO & SEM oraz social media",
    "Przegląd nowości z zakresu digital marketingu",
    "Zagadnienia i porady prawne"
];

interface IProps {
    partners: IPartner[] | null;
}

export const HeroSection = (props: IProps) => {
    const essentialPartners = (props.partners || []).filter((partner) => partner.is_essential);
    const nonEssentialPartners = (props.partners || []).filter((partner) => !partner.is_essential);

    return (
        <HeroContainer>
            <ImgColorMultiply />

            <ContentWrapper>
                <ContentWidth>
                    <Title>W KAŻDYM NUMERZE ZNAJDZIESZ:</Title>

                    <Checklist items={HERO_CHECKLIST} />

                    {props.partners && (
                        <Patrons>
                            <p>Patron merytoryczny:</p>

                            {essentialPartners.map((item) => {
                                return <PatronImg style={{backgroundImage: `url(${item.logo})`}} key={item.name} />;
                            })}

                            <p>Partnerzy:</p>

                            {nonEssentialPartners.map((item) => {
                                return <PatronImg style={{backgroundImage: `url(${item.logo})`}} key={item.name} />;
                            })}
                        </Patrons>
                    )}
                </ContentWidth>
            </ContentWrapper>
        </HeroContainer>
    );
};

const HeroContainer = styled("section")`
    ${psr};
    background-image: url("${hero}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 720px;
    margin: 100px 0 30px 0;

    @media (max-width: 1366px) {
        margin-top: 100px;
    }

    @media (max-width: 1025px) {
        height: 600px;
        margin-top: 80px;
    }
`;

const ImgColorMultiply = styled("div")`
    ${psa};
    ${h100};
    background-color: rgba(20, 41, 77, 0.95);
    mix-blend-mode: multiply;
    transform: skew(20deg);
    margin-left: -200px;
    width: 60%;

    @media (max-width: 1200px) {
        width: 65%;
    }

    @media (max-width: 1100px) {
        width: 70%;
    }

    @media (max-width: 1025px) {
        ${h100};
        ${w100};
        margin-left: 0;
        transform: none;
    }
`;

const ContentWrapper = styled("div")`
    ${psr};
    ${z1};
    ${c_white};
    ${h100};
    ${df};
    ${fd_col};
    ${fjc_center};
    ${m_0a};
    ${ph_xl};
    max-width: 1400px;
`;

const ContentWidth = styled("div")`
    max-width: 50%;

    @media (max-width: 1025px) {
        max-width: 100%;
    }
`;

const Patrons = styled("div")`
    ${psa};
    ${z2};
    ${bgc_white};
    ${c_black};
    ${df};
    ${fd_col};
    ${fai_center};
    min-width: 260px;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.29);
    padding: 25px 35px;
    border-radius: 5px;
    bottom: -50px;
    right: 150px;

    p {
        ${fs16};
        margin-bottom: 20px;
    }

    div:last-of-type {
        margin-bottom: 0;
    }

    @media (max-width: 1100px) {
        right: 70px;
    }

    @media (max-width: 1025px) {
        ${dn};
    }
`;

const PatronImg = styled("div")`
    width: 100%;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-bottom: 15px;
`;
