import React from "react";
import styled from "@emotion/styled";

import {Title} from "./commonStyledElements";

interface IProps {
    title: string;
    lineColor?: string;
}

export const SectionTitle = (props: IProps) => {
    return (
        <TitleHolder>
            <TitleLine color={props.lineColor} />

            <TitleWrapper color={props.lineColor}>{props.title}</TitleWrapper>

            <TitleLine color={props.lineColor} />
        </TitleHolder>
    );
};

const TitleHolder = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
`;

const TitleLine = styled("div")`
    flex-grow: 1;
    flex-shrink: 1;
    height: 1px;
    border-bottom: 1px solid ${(props: {color?: string}) => props.color || "#0C284F"};
`;

const TitleWrapper = styled(Title)`
    flex-grow: 0;
    flex-shrink: 0;
    text-transform: uppercase;
    margin-bottom: 0 !important;
    padding: 0 10px;
    color: ${(props: {color?: string}) => props.color || "#0C284F"};
`;
