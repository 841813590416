import React, {useEffect, useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {IPartnerOpinion} from "../api/get_partners_opinions";
import {SectionTitle} from "./common/SectionTitle";
import {BasicSlider} from "./common/slider/Slider";
import {CloseIcon} from "./icons/CloseIcon";
import {ContentContainer} from "./Archive";
import {Modal} from "./Modal";

import quote from "../images/quote.svg";

interface IProps {
    opinions: IPartnerOpinion[];
}

export const Opinion = (props: IProps) => {
    const mobileBreakpoint = 1024;
    const characterCounter = 150;

    const [isModalOpen, setModalOpen] = useState(false);
    const [openedOpinion, setOpenedOpinion] = useState(0);
    const [slidesCounter, setSlidesCounter] = useState(window.innerWidth < mobileBreakpoint ? 1 : 3);

    const sliderConfig = {
        infinite: true,
        slidesToShow: slidesCounter,
        slidesToScroll: 1
    };

    const modalFullContent = {
        backgroundColor: "#fff",
        overflow: "hidden",
        outline: "none",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        borderRadius: 0,
        height: "auto",
        width: "390px"
    };

    const resizeHandler = () => {
        if (window.innerWidth < mobileBreakpoint) {
            return setSlidesCounter(1);
        }

        return setSlidesCounter(3);
    };

    const truncateString = (text: string, count: number) => {
        if (text.length > count) {
            return text.slice(0, count) + '..."';
        }
        return text;
    };

    const openOpinion = (opinion: string, index: number) => {
        if (opinion.length < characterCounter) {
            return;
        }
        setOpenedOpinion(index);
        setModalOpen(true);
    };

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    const renderModalContent = (index: number) => {
        return (
            <>
                <ModalHeader onClick={() => setModalOpen(false)}>
                    <CloseButton color="#000" width={15} height={15} />
                </ModalHeader>

                <ModalOpinionBox>
                    <OpinionImgHolder>
                        <OpinionImage src={props.opinions[index].partner.logo} alt={props.opinions[index].partner.name} height={120} width={120} />
                    </OpinionImgHolder>

                    <DeveloperOpinionText className="modal">
                        <span>{props.opinions[index].text}</span>
                    </DeveloperOpinionText>
                </ModalOpinionBox>
            </>
        );
    };

    const renderOpinionBoxes = () =>
        props.opinions.map((element, index) => (
            <OpinionBoxWrapper key={index} onClick={() => openOpinion(element.text, index)} isCursorPointer={element.text.length > characterCounter}>
                <OpinionImgHolder>
                    <OpinionImage src={element.partner.logo} alt={element.partner.name} height={120} width={120} />
                </OpinionImgHolder>

                <DeveloperOpinionText>{truncateString(element.text, characterCounter)}</DeveloperOpinionText>
            </OpinionBoxWrapper>
        ));

    return (
        <Container>
            <SectionTitle title="Opinie Deweloperów" />

            <ContentContainerCustom>
                <Slider sliderConfig={sliderConfig}>{renderOpinionBoxes()}</Slider>
            </ContentContainerCustom>

            <Modal modalState={isModalOpen} onModalClose={() => setModalOpen(false)} type="window" contentStyles={modalFullContent}>
                {renderModalContent(openedOpinion)}
            </Modal>
        </Container>
    );
};

const Container = styled("section")`
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
`;

const ContentContainerCustom = styled(ContentContainer)`
    max-width: 1200px;

    @media screen and (max-width: 1280px) {
        max-width: 1200px; // override breakpoint from ContentContainer
    }

    .slide {
        &:focus {
            outline: none !important;
        }
    }

    .left,
    .right {
        z-index: 2;

        &:after {
            background-color: rgba(51, 51, 51, 0.45) !important;
        }
    }
`;

const cursorType = (props: {isCursorPointer: boolean}) => css`
    cursor: ${props.isCursorPointer ? "pointer" : "auto"};
`;

const OpinionBoxWrapper = styled("div")<{isCursorPointer: boolean}>`
    height: 296px;
    width: 330px;
    border: 1px solid #dddddd;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 30px;
    margin: 30px auto;
    outline: none;
    ${(props) => cursorType(props)}

    > div {
        width: 120px;
        height: 120px;
    }

    &:after {
        content: url("${quote}");
        position: absolute;
        top: -25px;
        left: -30px;
        height: 70px;
        width: 60px;

        @media screen and (max-width: 1024px) {
            left: -10px;
        }
    }

    @media screen and (max-width: 1024px) {
        width: 300px;
        height: 250px;
        padding: 10px;
    }
`;

const ModalOpinionBox = styled("div")`
    min-height: 296px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    margin: 0 auto;
    height: auto;

    > div {
        width: 120px;
        height: 120px;
    }
`;

const OpinionImgHolder = styled("div")`
    margin-bottom: 10px;
`;

const OpinionImage = styled("img")`
    border: 1px solid #979797;
    border-radius: 50%;
    object-fit: contain;
    height: 100% !important;
    margin-bottom: 20px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 0;
    }
`;

const DeveloperOpinionText = styled("p")`
    color: #0c284f;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;

    &.modal {
        max-height: 300px;
        overflow: auto;
    }
`;

const Slider = styled(BasicSlider)`
    height: 100%;
`;

const ModalHeader = styled("div")`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const CloseButton = styled(CloseIcon)`
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
`;
