import {css} from "@emotion/react";

import {
    blockquote_border_color,
    blockquote_font_size,
    blockquote_small_color,
    brand_primary,
    dl_horizontal_breakpoint,
    dl_horizontal_offset,
    font_size_base,
    font_size_h1,
    font_size_h2,
    font_size_h3,
    font_size_h4,
    font_size_h5,
    font_size_h6,
    font_size_small,
    headings_color,
    headings_font_family,
    headings_font_weight,
    headings_line_height,
    headings_small_color,
    line_height_base,
    line_height_computed,
    page_header_border_color,
    screen_min_sm,
    state_danger_bg,
    state_danger_text,
    state_info_bg,
    state_info_text,
    state_success_bg,
    state_success_text,
    state_warning_bg,
    state_warning_text,
    text_muted
} from "./variables";

export const types = css`
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: ${headings_font_family};
  font-weight: ${headings_font_weight};
  line-height: ${headings_line_height};
  color: ${headings_color};

  small,
  .small {
    font-weight: 400;
    line-height: 1;
    color: ${headings_small_color};
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: ${line_height_computed}px;
  margin-bottom: calc(${line_height_computed}px / 2);

  small,
  .small {
    font-size: 65%;
  }
}
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: calc(${line_height_computed}px / 2);
  margin-bottom: calc(${line_height_computed}px / 2);

  small,
  .small {
    font-size: 75%;
  }
}

h1, .h1 { font-size: ${font_size_h1}; }
h2, .h2 { font-size: ${font_size_h2}; }
h3, .h3 { font-size: ${font_size_h3}; }
h4, .h4 { font-size: ${font_size_h4}; }
h5, .h5 { font-size: ${font_size_h5} }
h6, .h6 { font-size: ${font_size_h6}; }

p {
  margin: 0 0 calc(${line_height_computed}px / 2);
}

.lead {
  margin-bottom: ${line_height_computed}px;
  font-size: floor((${font_size_base} * 1.15));
  font-weight: 300;
  line-height: 1.4;

  @media (min-width: ${screen_min_sm}) {
    font-size: (${font_size_base} * 1.5);
  }
}

small,
.small {
  font-size: floor((100% * ${font_size_small} / ${font_size_base}));
}

mark,
.mark {
  padding: .2em;
  background-color: ${state_warning_bg};
}

.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }

.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

.text-muted {
  color: ${text_muted};
}
.text-primary {
  .text-emphasis-variant(${brand_primary});
}
.text-success {
  .text-emphasis-variant(${state_success_text});
}
.text-info {
  .text-emphasis-variant(${state_info_text});
}
.text-warning {
  .text-emphasis-variant(${state_warning_text});
}
.text-danger {
  .text-emphasis-variant(${state_danger_text});
}

.bg-primary {
  color: #fff;
  .bg-variant(${brand_primary});
}
.bg-success {
  .bg-variant(${state_success_bg});
}
.bg-info {
  .bg-variant(${state_info_bg});
}
.bg-warning {
  .bg-variant(${state_warning_bg});
}
.bg-danger {
  .bg-variant(${state_danger_bg});
}

.page-header {
  padding-bottom: calc(calc(${line_height_computed}px / 2) - 1px);
  margin: calc(${line_height_computed}px * 2) 0 ${line_height_computed}px;
  border-bottom: 1px solid ${page_header_border_color};
}

ul,
ol {
  margin-top: 0;
  margin-bottom: calc(${line_height_computed}px / 2);
  ul,
  ol {
    margin-bottom: 0;
  }
}


.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;

  > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
}

dl {
  margin-top: 0;
  margin-bottom: ${line_height_computed};
}
dt,
dd {
  line-height: ${line_height_base};
}
dt {
  font-weight: 700;
}
dd {
  margin-left: 0;
}

.dl-horizontal {
  dd {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
  }

  @media (min-width: ${dl_horizontal_breakpoint}) {
    dt {
      float: left;
      width: (${dl_horizontal_offset} - 20px);
      clear: left;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    dd {
      margin-left: ${dl_horizontal_offset};
    }
  }
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

blockquote {
  padding: calc(${line_height_computed}px / 2) ${line_height_computed}px;
  margin: 0 0 ${line_height_computed}px;
  font-size: ${blockquote_font_size};
  border-left: 5px solid ${blockquote_border_color};

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  footer,
  small,
  .small {
    display: block;
    font-size: 80%;
    line-height: ${line_height_base};
    color: ${blockquote_small_color}

    &:before {
      content: "\\2014 \\00A0";
    }
  }
}


.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid ${blockquote_border_color};
  border-left: 0;

  footer,
  small,
  .small {
    &:before { content: ""; }
    &:after {
      content: "\\00A0 \\2014";
    }
  }
}


address {
  margin-bottom: ${line_height_computed}px;
  font-style: normal;
  line-height: ${line_height_base};
}

button, ul, li {
  font-size: 1.4rem;
}

`;
