import React from "react";
import cn from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const LinkedInIcon = (props: IProps) => {
    return (
        <svg className={cn(props.className, "svg-icon")} width={props.width || "28.49"} height={props.height || "28.49"} viewBox="0 0 28.49 28.49">
            <path d="M15.68,12.6v0l0,0Z" fill="#312c30" fillRule="evenodd" />
            <path
                d="M22.29,20.65a.23.23,0,0,1-.23.23H19.31a.23.23,0,0,1-.24-.23V15.7c0-1.3-.46-2.19-1.63-2.19a1.76,1.76,0,0,0-1.65,1.18,2.26,2.26,0,0,0-.11.78v5.18a.23.23,0,0,1-.23.23H12.7a.23.23,0,0,1-.24-.23c0-1.32,0-7.71,0-9.21a.23.23,0,0,1,.24-.24h2.74a.23.23,0,0,1,.23.23v1.14A3.21,3.21,0,0,1,18.59,11c2.12,0,3.7,1.38,3.7,4.36ZM9,10A1.73,1.73,0,1,1,10.7,8.24,1.72,1.72,0,0,1,9,10Zm1.71,10.69a.23.23,0,0,1-.24.23H7.7a.23.23,0,0,1-.24-.23V11.43a.23.23,0,0,1,.24-.23h2.74a.23.23,0,0,1,.24.23ZM14.24,0A14.25,14.25,0,1,0,28.49,14.24,14.26,14.26,0,0,0,14.24,0Z"
                fill={props.mainColor || "#0c284f"}
                fillRule="evenodd"
            />
        </svg>
    );
};
