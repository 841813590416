import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {df, fai_center, fd_col, fjc_sa, flex_centered} from "../styles/flexbox";
import {c_white, fs14, fs20, fs24, fs26, fs30, m_0a} from "../styles/helpers";
import {SectionTitle} from "./common/SectionTitle";
import {EnvelopeIcon} from "./icons/EnvelopeIcon";
import {MarkerOutlineIcon} from "./icons/MarkerOutlineIcon";
import {PhoneIcon} from "./icons/PhoneIcon";

interface IOwnProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
}

export const Contact = (props: IOwnProps) => {
    return (
        <Footer ref={props.refElem}>
            <SectionTitle title="Kontakt" lineColor="#fff" />

            <ContentHolder>
                <ContactDataWrapper>
                    <InfoHolder>
                        <IconHolder>
                            <MarkerOutlineIcon mainColor="#fff" width={50} height={50} />
                        </IconHolder>

                        <div css={[df, fd_col]}>
                            <span css={infoBold}>Nasz adres</span>
                            <ContactLink href="https://goo.gl/maps/PhK5mC6A4ya6fw9p6" target="_blank">
                                ul. Naruszewicza 27 lok. 101
                            </ContactLink>
                        </div>
                    </InfoHolder>

                    <InfoHolder>
                        <IconHolder>
                            <EnvelopeIcon mainColor="#fff" width={45} height={45} />
                        </IconHolder>

                        <div css={[df, fd_col]}>
                            <span css={infoBold}>Email</span>
                            <ContactLink href="mailto:pr@rynekpierwotny.pl">pr@rynekpierwotny.pl</ContactLink>
                        </div>
                    </InfoHolder>

                    <InfoHolder>
                        <IconHolder>
                            <PhoneIcon mainColor="#fff" width={45} height={45} />
                        </IconHolder>

                        <div css={[df, fd_col]}>
                            <span css={infoBold}>Numer telefonu</span>
                            <ContactLink href="tel:228256071">+48 (22) 825 60 71</ContactLink>
                        </div>
                    </InfoHolder>
                </ContactDataWrapper>

                <div style={{fontWeight: 300}}>
                    Wydawca serwisu www.rynekpierwotny.pl jest Property Group Sp. z o.o. ul. A. Naruszewicza 27/101, 02-627 Warszawa REGON 141961782, NIP
                    5213538080. Spółka jest zarejestrowana przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru
                    Sądowego pod numerem KRS 0000335123. Kapitał zakładowy w wysokości 50 000 zł.
                </div>
            </ContentHolder>
        </Footer>
    );
};

const Footer = styled("footer")`
    margin-top: 30px;
    padding: 30px 20px 50px 20px;
    background: linear-gradient(180deg, #113669 0%, #0c284f 100%);
    ${c_white}
`;

const ContentHolder = styled("div")`
    ${m_0a};
    max-width: 1400px;
`;

const ContactDataWrapper = styled("div")`
    ${df};
    ${fjc_sa};
    ${fs14};
    margin-bottom: 70px;
    @media (max-width: 990px) {
        margin-bottom: 10px;
        ${fd_col}
        ${fai_center}
    }
`;

const infoBold = css`
    font-weight: 400;
`;

const ContactLink = styled("a")`
    ${fs24}
    ${c_white}
  &:hover, &:focus {
        text-decoration: none;
        color: #e0e0e0;
    }
    @media (max-width: 990px) {
        ${fs20}
    }
`;

const InfoHolder = styled("div")`
    ${df}
    ${fai_center}
    ${fs30}
    font-weight: 300;
    @media (max-width: 990px) {
        ${fs26};
        width: 310px;
        margin-bottom: 40px;
    }
`;

const IconHolder = styled("div")`
    width: 60px;
    height: 60px;
    margin-right: 20px;
    ${flex_centered}
    @media(max-width: 990px) {
        margin-right: 10px;
    }
`;
