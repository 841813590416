import {dmApiLink} from "@pg-mono/dm-routes";
import {getRequest} from "@pg-mono/request";

import {IPaginatedResponse} from "../types/IPaginatedResponse";

export interface IPartner {
    is_essential: boolean;
    logo: string;
    name: string;
}

export const getPartners = (): Promise<IPartner[]> => {
    const url = dmApiLink.partners.base();

    return getRequest({}, url).then((res: IPaginatedResponse<IPartner>) => {
        return res.results;
    });
};
