import React, {useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import cn from "classnames";

import {Facebook2Icon} from "./icons/Facebook2Icon";
import {InstagramIcon} from "./icons/InstagramIcon";
import {LinkedInIcon} from "./icons/LinkedInIcon";
import {HamburgerTrigger} from "./HamburgerTrigger";
import {Logo} from "./Logo";

// import {RynekPierwotnyLogoIcon} from "./RynekPierwotnyLogoIcon";
import logoDM from "../images/logo_dm.svg";
import logoRP from "../images/logo_dm.svg";

export interface INavLink {
    text: string;
    url: React.RefObject<HTMLTableSectionElement>;
}

interface IProps {
    links: INavLink[];
    scrollTo: (duration: number, refElem: React.RefObject<HTMLTableSectionElement>) => (e: React.MouseEvent) => void;
}

export const NavigationMobile = (props: IProps) => {
    const [navOpened, setNavOpened] = useState(false);
    const navMobileClassName = cn("nav-mobile", navOpened && "opened");
    const NAV_MAIN_COLOR = "#0c284f";
    const customStyles = {
        navBarColor: "#fff",
        fontColor: NAV_MAIN_COLOR,
        asidePanelColor: NAV_MAIN_COLOR,
        asideFontColor: "#fff",
        triggerColor: NAV_MAIN_COLOR,
        invertedTriggerColor: "#fff"
    };

    const logo = {
        logoSrc: logoDM,
        url: "/",
        height: 60,
        width: 160
    };

    const additionalElement = () => {
        return (
            <>
                <SocialIconHolder>
                    <SocialLinkWrapper href="https://www.facebook.com/groups/deweloper.i.marketing/" target="_blank">
                        <Facebook2Icon height={30} width={30} mainColor="#fff" />
                    </SocialLinkWrapper>

                    <SocialLinkWrapper href="https://www.instagram.com/rynekpierwotny/" target="_blank">
                        <InstagramIcon height={30} width={30} mainColor="#fff" />
                    </SocialLinkWrapper>

                    <SocialLinkWrapper href="https://www.linkedin.com/company/3503462/ " target="_blank">
                        <LinkedInIcon height={30} width={30} mainColor="#fff" />
                    </SocialLinkWrapper>
                </SocialIconHolder>

                <LogoWrapper>
                    <p>Wydawcą magazynu jest portal</p>
                    <a href="https://rynekpierwotny.pl" target="_blank">
                        <img src={logoRP} width={200} height={44} alt="logo_rynek_pierwotny" />
                    </a>
                </LogoWrapper>
            </>
        );
    };

    return (
        <>
            <HeaderMobileWrapper className="header-mobile" backgroundColor={customStyles.navBarColor} fontColor={customStyles.fontColor}>
                <Logo {...logo} />
                <HamburgerTrigger
                    onClick={() => setNavOpened(!navOpened)}
                    opened={navOpened}
                    color={customStyles.triggerColor}
                    invertedTriggerColor={customStyles.invertedTriggerColor}
                />
                <NavMobile className={navMobileClassName} asidePanelColor={customStyles.asidePanelColor}>
                    {props.links.map((link, i) => (
                        <NavLinkMobile key={i} fontColor={customStyles.asideFontColor} onClick={props.scrollTo(300, link.url)}>
                            <div onClick={() => setNavOpened(!navOpened)}>{link.text}</div>
                        </NavLinkMobile>
                    ))}

                    {additionalElement()}
                </NavMobile>
            </HeaderMobileWrapper>
        </>
    );
};

const customStylesMixin = (props: {backgroundColor?: string; fontColor?: string; asidePanelColor?: string; asideFontColor?: string}) => css`
    background-color: ${props.backgroundColor};
    background-color: ${props.asidePanelColor};
    color: ${props.fontColor};
    color: ${props.asidePanelColor};
`;

const HeaderMobileWrapper = styled("div")`
    font-size: 25px;
    font-weight: 300;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: #9069c0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${customStylesMixin};
    transition: background-color 300ms ease;
    z-index: 120;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

export const NavMobile = styled("nav")`
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    padding: 40px 0 0 0;
    transition: all 0.5s;
    background-color: #37474f;
    z-index: 120;
    ${customStylesMixin};

    &.opened {
        right: 0;
    }

    @media screen and (min-width: 1025px) {
        display: none;
    }
`;

export const NavLinkMobile = styled("div")`
    display: block;
    border-bottom: 1px solid #fff;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    ${customStylesMixin};

    > div {
        padding: 16px 30px;
    }
`;

const LogoWrapper = styled("div")`
    color: white;
    padding: 16px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
`;

const SocialIconHolder = styled("div")`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    color: white;
    padding: 16px 30px;
    border-bottom: 1px solid #fff;

    .svg-icon {
        margin-right: 20px;
    }
`;

const SocialLinkWrapper = styled("a")``;
