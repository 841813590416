import {css} from "@emotion/react";

export const df = css`
    display: flex;
`;
export const fd_col = css`
    flex-direction: column;
`;
export const fjc_center = css`
    justify-content: center;
`;
export const fjc_sa = css`
    justify-content: space-around;
`;
export const fai_center = css`
    align-items: center;
`;
export const fs_0 = css`
    flex-shrink: 0;
`;
export const flex_centered = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;
