import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";

interface IProps {
    height?: number;
    width?: number;
    fill?: string;
    orientation?: "up" | "right" | "down" | "left";
    className?: string;
}

export const ArrowWithOrientationIcon = (props: IProps) => {
    return (
        <Arrow width={props.width || 30} height={props.height || 35} viewBox="0 0 12 6.75" className={cn(props.orientation, props.className, "svg-icon")}>
            <polyline points="1 1 6 5.75 11 1" fill="none" stroke={props.fill || "#fff"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </Arrow>
    );
};

const Arrow = styled("svg")`
    &.up {
        transform: rotate(180deg);
    }

    &.right {
        transform: translate(0, 0) rotate(270deg);
    }

    &.down {
        transform: rotate(360deg);
    }

    &.left {
        transform: translate(-5px, 0px) rotate(90deg);
    }
`;
