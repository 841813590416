import {dmApiLink} from "@pg-mono/dm-routes";
import {getRequest} from "@pg-mono/request";

import {IPaginatedResponse} from "../types/IPaginatedResponse";

export interface IIssue {
    cover_image: string;
    file: string;
    headlines: string[];
    main_page_image: string;
    number: string;
}

export const getIssues = (): Promise<IIssue[]> => {
    const url = dmApiLink.issues();

    return getRequest({}, url).then((res: IPaginatedResponse<IIssue>) => {
        return res.results;
    });
};
