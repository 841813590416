import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

export interface ILogoProps {
    logoSrc: string;
    url: string;
    height: number;
    width: number;
}

export const Logo = (props: ILogoProps) => (
    <NavLogo href={props.url}>
        <LogoImage logoSrc={props.logoSrc} height={props.height} width={props.width} />
    </NavLogo>
);

const NavLogo = styled("a")`
    padding: 10px;
`;

const imageStyles = (props: {height: number; width: number; logoSrc: string}) => css`
    height: ${`${props.height}px`};
    width: ${`${props.width}px`};
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("${props.logoSrc}");
`;

const LogoImage = styled("div")(imageStyles);
