import React from "react";
import Slider, {Settings} from "react-slick";
import styled from "@emotion/styled";

import {SliderArrow} from "./SliderArrow";

interface IProps {
    children: React.ReactNode[];
    sliderConfig?: Settings;
}

export const BasicSlider = (props: IProps) => {
    const defaultSettings: Settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <SliderHolder className="slider-holder">
            <Slider
                {...(props.sliderConfig || defaultSettings)}
                className="slider-main-slide-holder"
                nextArrow={<SliderArrow orientation="right" />}
                prevArrow={<SliderArrow orientation="left" />}
            >
                {props.children.map((slide, index) => {
                    return (
                        <div key={index} className="slide">
                            {slide}
                        </div>
                    );
                })}
            </Slider>
        </SliderHolder>
    );
};

const SliderHolder = styled("div")`
    height: 100%;
    overflow: hidden;

    .slick-slider {
        height: 100%;

        .slick-list {
            height: 100%;
        }

        .slick-track {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;

            .slick-slide {
                float: none;
                display: flex;
                align-items: center;

                .slide {
                    img {
                        display: block;
                        max-width: 100%;
                        height: auto;
                    }
                }
            }

            .slick-current {
                > div {
                    width: 100%;
                }
            }
        }
    }

    .slick-prev,
    .slick-next {
        position: absolute;
        z-index: 1;
        transform: translate(0, -70%);

        &:before {
            font-size: 3rem;
            position: relative;
            top: 0.2rem;
        }
    }

    .slick-slider {
        &.slick-arrow-sm {
            .slick-prev,
            .slick-next {
                z-index: 1;
                transform: translate(0, -70%);

                &:before {
                    font-size: 2rem;
                    position: relative;
                    top: 0.2rem;
                }
            }

            .slick-next {
                &:before {
                    margin-left: -3px;
                }
            }

            .slick-prev {
                &:before {
                    margin-right: -3px;
                }
            }
        }
    }

    .slick-prev {
        left: 0;
        bottom: 50%;
    }

    .slick-next {
        right: 0;
        bottom: 50%;
    }

    .slick-slider {
        .gradient-figcaption {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            color: #fff;
            padding: 30px 10px 10px;
        }
    }
`;
