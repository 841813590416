import React from "react";
import styled from "@emotion/styled";
import {darken} from "polished";

import {Facebook2Icon} from "./icons/Facebook2Icon";
import {InstagramIcon} from "./icons/InstagramIcon";
import {LinkedInIcon} from "./icons/LinkedInIcon";
import {INavLink} from "./NavigationMobile";
import {RynekPierwotnyLogoIcon} from "./RynekPierwotnyLogoIcon";

import logoDM from "../images/logo_dm.svg";

interface IOwnProps {
    links: INavLink[];
    scrollTo: (duration: number, refElem: React.RefObject<HTMLTableSectionElement>) => (e: React.MouseEvent) => void;
}

export const NavigationDesktop = (props: IOwnProps) => {
    const {scrollTo} = props;

    return (
        <Nav>
            <NavLogo href="/">
                <Logo />
            </NavLogo>

            {props.links.map((link, i) => (
                <NavLink key={i} onClick={scrollTo(300, link.url)}>
                    {link.text}
                </NavLink>
            ))}

            <SocialWrapper>
                <SocialIconHolder>
                    <SocialLinkWrapper href="https://www.facebook.com/groups/deweloper.i.marketing/" target="_blank">
                        <Facebook2Icon height={30} width={30} />
                    </SocialLinkWrapper>

                    <SocialLinkWrapper href="https://www.instagram.com/rynekpierwotny/" target="_blank">
                        <InstagramIcon height={30} width={30} />
                    </SocialLinkWrapper>

                    <SocialLinkWrapper href="https://www.linkedin.com/company/3503462/ " target="_blank">
                        <LinkedInIcon height={30} width={30} />
                    </SocialLinkWrapper>
                </SocialIconHolder>

                <LogoWrapper>
                    <p>Wydawcą magazynu jest portal</p>
                    <a href="https://rynekpierwotny.pl" target="_blank">
                        <RPLogo />
                    </a>
                </LogoWrapper>
            </SocialWrapper>
        </Nav>
    );
};

const NAV_MAIN_COLOR = "#0c284f";

const Logo = styled("div")`
    height: 50px;
    width: 150px;
    background-size: contain !important;
    background: url("${logoDM}") no-repeat;

    @media screen and (min-width: 1600px) {
        height: 60px;
        width: 180px;
    }

    @media screen and (min-width: 1920px) {
        height: 90px;
        width: 263px;
        margin-right: 70px;
    }
`;

const NavLogo = styled("a")`
    display: flex;
    align-items: center;
    padding: 10px;

    @media screen and (min-width: 1024px) {
        padding-left: 20px;
    }
`;

const Nav = styled("nav")`
    display: none;

    @media screen and (min-width: 1024px) {
        height: 100px;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        position: fixed;
        background-color: #fff;
        color: ${NAV_MAIN_COLOR};
        top: 0;
        left: 0;
        right: 0;
        z-index: 120;
    }

    @media screen and (min-width: 1600px) {
        justify-content: center;
        height: 140px;
    }
`;

const NavLink = styled("div")`
    transition: background-color 300ms ease-out;
    padding: 12px;
    margin-right: 12px;
    text-decoration: none;
    text-transform: uppercase;
    color: ${NAV_MAIN_COLOR};
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.42px;
    line-height: 37px;
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media screen and (min-width: 1600px) {
        font-size: 25px;
        padding: 20px;
        margin-right: 20px;
    }

    &:after {
        content: "";
        position: absolute;
        right: -20px;
        top: 50%;
        box-sizing: border-box;
        height: 2px;
        width: 26px;
        border: 1px solid #757575;
        transform: rotate(90deg);
    }

    &:hover {
        color: ${darken(0.05, NAV_MAIN_COLOR)};
        text-decoration: none;
    }

    &.active {
        color: ${darken(0.05, NAV_MAIN_COLOR)};
    }

    &:last-of-type {
        &:after {
            content: none;
        }
    }
`;

const SocialWrapper = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        white-space: nowrap;
        font-size: 18px;

        @media screen and (min-width: 1600px) {
            line-height: 36px;
            font-size: 24px;
        }
    }
`;

const SocialIconHolder = styled("div")`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: 10px;

    .svg-icon {
        margin-right: 20px;
    }
`;

const LogoWrapper = styled("div")`
    display: none;

    @media screen and (min-width: 1280px) {
        display: block;
        border-left: 2px solid #bababa;
        padding-left: 26px;
    }

    p {
        margin-bottom: 0px;
    }
`;

const RPLogo = styled(RynekPierwotnyLogoIcon)`
    margin-top: 5px;

    @media screen and (min-width: 1600px) {
        height: 47px;
        width: 220px;
    }
`;

const SocialLinkWrapper = styled("a")``;
