import {IGtmData} from "../types/IGtmData";

declare global {
    interface Window {
        dataLayer: object[];
    }
}

const MAX_IMPRESSIONS_PER_HIT = 10;

export const hitGoogleTagManager = <T extends IGtmData>(gtmData: T) => {
    const win = window as Window;

    win.dataLayer = win.dataLayer || [];

    if (gtmData && gtmData.ecommerce && gtmData.ecommerce.impressions && gtmData.ecommerce.impressions.length > MAX_IMPRESSIONS_PER_HIT) {
        // start impressions chunking
        const impressions = gtmData.ecommerce.impressions;
        const chunks = Math.ceil(impressions.length / MAX_IMPRESSIONS_PER_HIT);
        for (let i = 0; i < chunks; ++i) {
            const chunkData = structuredClone(gtmData);
            chunkData.ecommerce!.impressions = impressions.slice(i * MAX_IMPRESSIONS_PER_HIT, (i + 1) * MAX_IMPRESSIONS_PER_HIT);
            win.dataLayer.push(chunkData);
        }
    } else {
        win.dataLayer.push(gtmData);
    }
};
