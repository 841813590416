import {IGtmData} from "../types/IGtmData";
import {hitGoogleTagManager} from "./hit_google_tag_manager";

declare global {
    interface Window {
        dataLayer: object[];
        pgGtmLib: {
            gtmHitLib: (gtmData: IGtmData) => void;
        };
    }
}

let isGtmInitialized = false;

export const initGoogleTagManager = (gtmId: string): void => {
    const win = window as Window;

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }
    // initialize only once
    if (isGtmInitialized) {
        return;
    }
    // fetch/init GTM
    (function (w: Window, d, s: "script", dataLayer: "dataLayer", gtmId: string) {
        w[dataLayer] = w[dataLayer] || [];
        w[dataLayer].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js"
        });
        const firstScriptElem = d.getElementsByTagName(s)[0] as HTMLScriptElement;
        const newScriptElem = d.createElement(s);
        newScriptElem.async = true;
        newScriptElem.src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId;
        firstScriptElem.parentNode?.insertBefore(newScriptElem, firstScriptElem);
    })(win, document, "script", "dataLayer", gtmId);

    win.pgGtmLib = win.pgGtmLib || {};
    win.pgGtmLib.gtmHitLib = hitGoogleTagManager;

    isGtmInitialized = true;
};
