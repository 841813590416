import React, {Fragment} from "react";

import {NavigationDesktop} from "./NavigationDesktop";
import {INavLink, NavigationMobile} from "./NavigationMobile";

interface IOwnProps {
    refElems: {
        refAboutIssue: React.RefObject<HTMLTableSectionElement>;
        refLatestEdition: React.RefObject<HTMLTableSectionElement>;
        refArchive: React.RefObject<HTMLTableSectionElement>;
        refArticles: React.RefObject<HTMLTableSectionElement>;
        refContact: React.RefObject<HTMLTableSectionElement>;
    };
    scrollTo: (duration: number, refElem: React.RefObject<HTMLTableSectionElement>) => (e: React.MouseEvent) => void;
}

export const Navigation = (props: IOwnProps) => {
    const {refElems, scrollTo} = props;

    const links: INavLink[] = [
        {
            text: "O Kwartalniku",
            url: refElems.refAboutIssue
        },
        {
            text: "Najnowszy numer",
            url: refElems.refLatestEdition
        },
        {
            text: "Archiwum",
            url: refElems.refArchive
        },
        {
            text: "Artykuły",
            url: refElems.refArticles
        },
        {
            text: "Kontakt",
            url: refElems.refContact
        }
    ];

    return (
        <Fragment>
            <NavigationDesktop links={links} scrollTo={scrollTo} />

            <NavigationMobile links={links} scrollTo={scrollTo} />
        </Fragment>
    );
};
