import React from "react";
import cn from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const PhoneIcon = (props: IProps) => {
    return (
        <svg className={cn(props.className, "svg-icon")} width={props.width || "6.13mm"} height={props.height || "6.11mm"} viewBox="0 0 17.36 17.33">
            <path
                d="M15.32,10.12a9.55,9.55,0,0,1-2.06-.51,2.36,2.36,0,0,0-2.51.54l-.57.57A11.77,11.77,0,0,1,6.52,7.08l.58-.57A2.33,2.33,0,0,0,7.63,4a8.73,8.73,0,0,1-.51-2A2.28,2.28,0,0,0,4.75.12H2.65A.82.82,0,0,0,2.16,0,2.42,2.42,0,0,0,.58.76,2.15,2.15,0,0,0,0,2.47,16.54,16.54,0,0,0,2.57,9.7a16.37,16.37,0,0,0,5,5,16.57,16.57,0,0,0,7.21,2.58,1.18,1.18,0,0,0,.2,0h0a2.43,2.43,0,0,0,2.36-2.44V12.51A2.37,2.37,0,0,0,15.32,10.12Zm.48,4.77a.83.83,0,0,1-.87.81,15,15,0,0,1-6.52-2.32A14.94,14.94,0,0,1,3.88,8.85,15.08,15.08,0,0,1,1.57,2.32a.59.59,0,0,1,.16-.5.81.81,0,0,1,.32-.21.71.71,0,0,0,.32.07H4.75c.12,0,.74,0,.82.56a10.16,10.16,0,0,0,.6,2.35A.76.76,0,0,1,6,5.4l-1,1a.79.79,0,0,0-.13,1,13.55,13.55,0,0,0,5.06,5.05.79.79,0,0,0,.94-.13l1-1a.8.8,0,0,1,.85-.18,11,11,0,0,0,2.39.6.83.83,0,0,1,.7.84Z"
                fill={props.mainColor || "#9a9999"}
            />
        </svg>
    );
};
