import React from "react";
import styled from "@emotion/styled";

import {IArticle} from "../api/get_articles";
import {df, fai_center, fd_col, fjc_center} from "../styles/flexbox";
import {m_0a, ph_xl} from "../styles/helpers";
import {SectionTitle} from "./common/SectionTitle";
import {ArticleBox} from "./ArticleBox";

interface IProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
    articles: IArticle[];
}

export const Articles = (props: IProps) => {
    return (
        <Container ref={props.refElem}>
            <SectionTitle title="Artykuły" />

            <ArticlesHolder>
                {props.articles.map((item, index) => {
                    if (index === props.articles.length) {
                        return <ArticleBox key={index} img={item.image} title={item.title} lead={item.description} articleLink={item.url} />;
                    }

                    return (
                        <ArticleWrapper key={index}>
                            <ArticleBox img={item.image} title={item.title} lead={item.description} articleLink={item.url} />
                        </ArticleWrapper>
                    );
                })}
            </ArticlesHolder>
        </Container>
    );
};

const Container = styled("section")`
    margin-top: 30px;
    margin-bottom: 30px;
`;

const ArticlesHolder = styled("div")`
    ${m_0a};
    ${ph_xl};
    ${df};
    ${fjc_center};
    ${fai_center};
    max-width: 1400px;
    @media (max-width: 980px) {
        ${fd_col}
    }
`;

const ArticleWrapper = styled("div")`
    margin-right: 30px;

    @media (max-width: 980px) {
        margin-right: 0;
        margin-bottom: 20px;
    }
`;
