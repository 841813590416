/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import ReactModal from "react-modal";
import styled from "@emotion/styled";
import cn from "classnames";

import {CloseButtonIcon} from "./icons/CloseButtonIcon";

export interface IModalOwnProps {
    type?: "full" | "window";
    onModalClose?(event: any): void;
    modalState: boolean;
    closeTimeout?: number;
    className?: string;
    overlayClassName?: string;
    contentLabel?: string;
    closeButton?: boolean;
    header?: string | JSX.Element;
    headerLogo?: boolean;
    headerClassName?: string;
    contentStyles?: any;
    overlayStyles?: any;
    children: React.ReactNode;
}

export class Modal extends React.Component<IModalOwnProps, {}> {
    public static defaultProps: any = {
        type: "full",
        closeTimeout: 150,
        contentLabel: "modal",
        closeButton: true
    };

    private isOpened = false;
    private timeoutHandler = 0;
    private pxFromTopViewport = 0;

    /**
     * Lifecycle
     */
    // Hide "behind the modal" content magic, browser only for it need to have an access to document.
    // See https://goo.gl/Gkbhfj
    public componentDidMount() {
        if (process.env.EXEC_ENV === "browser") {
            ReactModal.setAppElement("#root");
        }
    }

    public componentDidUpdate() {
        if (this.props.type === "full") {
            if (!this.isOpened && this.props.modalState) {
                this.openLogic();
            }
            if (this.isOpened && !this.props.modalState) {
                this.closeLogic();
            }
        }
    }

    public componentWillUnmount() {
        if (this.props.type === "full" && this.isOpened) {
            this.closeLogic();
        }
    }

    private openLogic = () => {
        // Fix the body
        this.isOpened = true;
        this.pxFromTopViewport = window.scrollY;
        const root = document.getElementById("root");

        if (root) {
            this.timeoutHandler = window.setTimeout(() => {
                root.style.position = "fixed";
                root.style.top = `-${this.pxFromTopViewport}`;
                root.style.left = "0";
                root.style.right = "0";
            }, 200);
        }
    };

    private closeLogic = () => {
        // Unfix the body
        this.isOpened = false;
        const root = document.getElementById("root");

        if (root) {
            clearTimeout(this.timeoutHandler);
            root.style.position = "";
            root.style.top = "";
            root.style.left = "";
            root.style.right = "";

            if (this.pxFromTopViewport) {
                window.setTimeout(() => {
                    // `scrollTo` did not work without `setTimeout`, maybe it was scrolling modal content
                    window.scrollTo(0, this.pxFromTopViewport);
                    this.pxFromTopViewport = 0;
                }, 0);
            }
        }
    };

    /**
     * Callback
     */

    private onModalClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        this.props.onModalClose && this.props.onModalClose(e);
    };

    /**
     * Render
     */

    public render() {
        const {props} = this;

        const className = cn(props.className, {"modal-full": props.type === "full"}, {"modal-window": props.type === "window"});

        const overlayClassName = cn("modal-overlay", props.overlayClassName);
        const headerClassName = cn("modal-header fg-1 fs18 truncate psr", props.headerClassName);
        const headerLogo = <div className="modal-header-logo" />;

        const modalHeader = (
            <div className="modal-header-holder df fs-0">
                <div className={headerClassName}>
                    {props.header}
                    {props.headerLogo && headerLogo}
                </div>
                {props.closeButton && (
                    <CloseButton onClick={this.onModalClose}>
                        <CloseButtonIcon mainColor="#fff" />
                    </CloseButton>
                )}
            </div>
        );

        return (
            <ReactModal
                isOpen={props.modalState}
                onRequestClose={this.onModalClose}
                closeTimeoutMS={props.closeTimeout}
                className={className}
                overlayClassName={overlayClassName}
                contentLabel={props.contentLabel || ""}
                style={{content: props.contentStyles || modalFullContent, overlay: props.overlayStyles || modalFullOverlay}}
            >
                {modalHeader}
                {props.children}
            </ReactModal>
        );
    }
}

const modalFullContent = {
    position: "absolute",
    backgroundColor: "#fff",
    outline: "none",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    borderRadius: 0,
    height: 350,
    width: 350
};

const modalFullOverlay = {
    position: "fixed",
    zIndex: "9000",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    backgroundColor: "rgba(0, 0, 0, .65)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const CloseButton = styled("div")`
    position: absolute;
    top: -32px;
    right: -32px;
    z-index: 9002;
    background-color: #000;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
`;
