import React from "react";
import cn from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const FacebookIcon = (props: IProps) => {
    return (
        <svg width={props.width || 22} height={props.height || 22} className={cn(props.className, "svg-icon")} viewBox="0 0 22 22">
            <rect width="22" height="22" rx="3.76" fill={props.mainColor || "#395398"} />
            <path
                d="M15.1,22V13.55h2.83l.42-3.29H15.1V8.16c0-1,.26-1.6,1.63-1.6h1.74V3.61a23.35,23.35,0,0,0-2.54-.13A4,4,0,0,0,11.7,7.83v2.43H8.85v3.29H11.7V22Z"
                fill="#fff"
            />
        </svg>
    );
};
