import {prefixPath} from "@pg-mono/prefix-path";

const dmApiPath = prefixPath("/api/", {
    articles: "articles/",
    issues: "issues/",
    partners: prefixPath("partners/", {
        base: "",
        opinions: "opinions/"
    })
});

export const dmApiLink = {
    articles: () => dmApiPath.articles,
    issues: () => dmApiPath.issues,
    partners: {
        base: () => dmApiPath.partners.base,
        opinions: () => dmApiPath.partners.opinions
    }
};
