import {dmApiLink} from "@pg-mono/dm-routes";
import {getRequest} from "@pg-mono/request";

import {IPaginatedResponse} from "../types/IPaginatedResponse";

export interface IArticle {
    description: string;
    image: string;
    title: string;
    url: string;
}

export const getArticles = (): Promise<IArticle[]> => {
    const url = dmApiLink.articles();

    return getRequest({}, url).then((res: IPaginatedResponse<IArticle>) => {
        return res.results;
    });
};
