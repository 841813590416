import {dmApiLink} from "@pg-mono/dm-routes";
import {getRequest} from "@pg-mono/request";

import {IPaginatedResponse} from "../types/IPaginatedResponse";

export interface IPartnerOpinion {
    partner: {
        logo: string;
        name: string;
    };
    text: string;
}

export const getPartnersOpinions = (): Promise<IPartnerOpinion[]> => {
    const url = dmApiLink.partners.opinions();

    return getRequest({}, url).then((res: IPaginatedResponse<IPartnerOpinion>) => {
        return res.results;
    });
};
