import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {df, fai_center, fs_0} from "../../styles/flexbox";
import {fs16, fs20, lsn, p_0} from "../../styles/helpers";
import {CheckIcon} from "../icons/CheckIcon";

interface IProps {
    items: string[];
    isDark?: boolean;
}

export const Checklist = (props: IProps) => {
    return (
        <List>
            {props.items.map((item) => (
                <ListItem color={props.isDark ? "#757575" : "#fff"} key={item}>
                    <ListItemIcon fill={props.isDark ? "#757575" : "#fff"} /> {item}
                </ListItem>
            ))}
        </List>
    );
};

const listColors = (props: {color?: string; fill?: string}) => css`
    color: ${props.color};
    fill: ${props.fill};
`;

const List = styled("ul")`
    ${lsn};
    ${p_0};

    @media (max-width: 1025px) {
        margin-bottom: 20px;
    }
`;

const ListItem = styled("li")`
    ${df};
    ${fai_center};
    font-size: 25px;
    letter-spacing: 0.42px;
    margin-bottom: 25px;
    ${listColors};

    @media (max-width: 1700px) {
        ${fs20};
        margin-bottom: 20px;
    }

    @media (max-width: 1350px) {
        ${fs16};
        margin-bottom: 15px;
    }
`;

const ListItemIcon = styled(CheckIcon)`
    width: 32px;
    height: 30px;
    fill: #fff;
    margin-right: 20px;
    ${listColors};
    ${fs_0}

    @media(max-width: 1700px) {
        margin-right: 15px;
        width: 27px;
        height: 25px;
    }

    @media (max-width: 1350px) {
        margin-right: 10px;
        width: 22px;
        height: 20px;
    }
`;
