import React from "react";
import cn from "classnames";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const InstagramIcon = (props: IProps) => {
    return (
        <svg className={cn(props.className, "svg-icon")} width={props.width || "28.33"} height={props.height || "28.33"} viewBox="0 0 28.33 28.33">
            <path d="M14.24,11.57a2.52,2.52,0,1,0,2.52,2.51A2.51,2.51,0,0,0,14.24,11.57Z" fill={props.mainColor || "#0c284f"} fillRule="evenodd" />
            <path
                d="M20.62,9.26A2.63,2.63,0,0,0,20,8.32a2.58,2.58,0,0,0-1-.61,4.62,4.62,0,0,0-1.51-.28c-.87,0-1.12,0-3.31,0s-2.44,0-3.3,0a4.69,4.69,0,0,0-1.52.28,2.55,2.55,0,0,0-.94.61,2.63,2.63,0,0,0-.61.94,4.43,4.43,0,0,0-.28,1.52c0,.86,0,1.12,0,3.31s0,2.44,0,3.3a4.43,4.43,0,0,0,.28,1.52,2.63,2.63,0,0,0,.61.94,2.55,2.55,0,0,0,.94.61,4.69,4.69,0,0,0,1.52.28c.86,0,1.12.05,3.3.05s2.44,0,3.31-.05a4.62,4.62,0,0,0,1.51-.28,2.74,2.74,0,0,0,1.56-1.55,4.69,4.69,0,0,0,.28-1.52c0-.86.05-1.12.05-3.3s0-2.45-.05-3.31A4.69,4.69,0,0,0,20.62,9.26Zm-6.38,9a4.2,4.2,0,1,1,4.2-4.19A4.2,4.2,0,0,1,14.24,18.28Zm4.37-7.58a1,1,0,1,1,1-1A1,1,0,0,1,18.61,10.7Z"
                fill={props.mainColor || "#0c284f"}
                fillRule="evenodd"
            />
            <path
                d="M14.16,0A14.17,14.17,0,1,0,28.33,14.16,14.16,14.16,0,0,0,14.16,0Zm8.09,17.52a6,6,0,0,1-.38,2,4.16,4.16,0,0,1-2.38,2.38,6,6,0,0,1-2,.38c-.87,0-1.15.05-3.36.05s-2.48,0-3.35-.05a6,6,0,0,1-2-.38,3.75,3.75,0,0,1-1.44-.94,3.83,3.83,0,0,1-.94-1.44,6,6,0,0,1-.38-2c0-.87,0-1.15,0-3.36s0-2.48,0-3.35a6,6,0,0,1,.38-2A3.75,3.75,0,0,1,7.4,7.4a3.75,3.75,0,0,1,1.44-.94,6,6,0,0,1,2-.38c.87,0,1.15,0,3.35,0s2.49,0,3.36,0a6,6,0,0,1,2,.38,3.83,3.83,0,0,1,1.44.94,3.75,3.75,0,0,1,.94,1.44,6,6,0,0,1,.38,2c0,.87.05,1.15.05,3.35S22.29,16.65,22.25,17.52Z"
                fill={props.mainColor || "#0c284f"}
                fillRule="evenodd"
            />
        </svg>
    );
};
