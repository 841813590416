import React from "react";
import cn from "classnames";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const CheckIcon = (props: IProps) => {
    return (
        <svg
            width={props.width || 28}
            height={props.height || 23}
            className={cn(props.className, "svg-icon")}
            viewBox="0 0 28 23"
            fill={props.mainColor || "#000"}
        >
            <path d="M11.5,23 C5.159,23 0,17.841 0,11.5 C0,5.159 5.159,0 11.5,0 C14.339,0 17.065,1.043 19.176,2.937 C19.793,3.49 19.845,4.438 19.292,5.055 C18.737,5.672 17.79,5.723 17.173,5.17 C15.613,3.771 13.598,3 11.5,3 C6.813,3 3,6.813 3,11.5 C3,16.187 6.813,20 11.5,20 C14.497,20 17.305,18.394 18.829,15.809 C19.25,15.095 20.17,14.856 20.883,15.278 C21.596,15.698 21.834,16.618 21.414,17.332 C19.353,20.828 15.554,23 11.5,23" />
            <path d="M11.1055,17.228 L5.4395,11.561 C4.8535,10.975 4.8535,10.025 5.4395,9.439 C6.0255,8.854 6.9745,8.854 7.5605,9.439 L12.1665,14.046 L22.4395,3.773 C23.0255,3.187 23.9745,3.187 24.5605,3.773 C25.1465,4.358 25.1465,5.309 24.5605,5.894 L13.2275,17.228 C12.9455,17.509 12.5645,17.667 12.1665,17.667 C11.7685,17.667 11.3875,17.509 11.1055,17.228 Z" />
        </svg>
    );
};
