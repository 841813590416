import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import {rgba} from "polished";

import {ArrowWithOrientationIcon} from "../../icons/ArrowWithOrientationIcon";

interface IProps {
    className?: string;
    extraClassName?: string; // prop className is used by ReactSlick and overwrites props passed manually
    onClick?(): void;
    orientation: "right" | "left";
}

export const SliderArrow = (props: IProps) => {
    const {onClick, orientation} = props;
    const className = (props.className && props.className.replace(/slick/g, ""), props.extraClassName) || "";

    return (
        <SlideArrowIcon className={cn(orientation, className)} onClick={onClick}>
            <ArrowWithOrientationIcon orientation={orientation} />
        </SlideArrowIcon>
    );
};

export const SlideArrowIcon = styled("div")`
    width: 60px;
    height: 120px;
    transition: background-color 150ms ease-out;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);

    &::after {
        width: 120px;
        height: 120px;
        content: "";
        position: absolute;
        transition: background-color 150ms ease-out;
        background-color: ${rgba("#fff", 0.45)};
        border-radius: 50%;
        right: 0;
    }

    &.right {
        right: 0;
        left: auto;
        padding-left: 0;

        &:after {
            right: auto;
            left: 0;
        }
    }

    &:hover {
        &::after {
            background-color: ${rgba("#fff", 0.6)};
        }
    }
`;
