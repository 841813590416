import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {df, fai_center, fjc_center} from "../../styles/flexbox";
import {dib, psr} from "../../styles/helpers";

interface IProps {
    width?: string;
    height?: string;
    color?: string;
}

export const ElementLoader = (props: IProps) => {
    return (
        <LoaderHolder width={props.width ? props.width : "50px"} height={props.height ? props.height : "50px"}>
            <Loader color={props.color ? props.color : "#0C284F"} />
        </LoaderHolder>
    );
};

const loaderHolderSize = (props: {width: string; height: string}) => css`
    width: ${props.width};
    height: ${props.height};
`;

const LoaderHolder = styled("div")`
    ${loaderHolderSize};
    ${df};
    ${fjc_center};
    ${fai_center}
`;

const loaderColor = (props: {color: string}) => css`
    border-top-color: ${props.color};
`;

export const Loader = styled("div")`
    ${dib};
    ${psr};
    width: 50px;
    height: 50px;
    &:before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        animation: spinner 0.6s linear infinite;
        width: 24px;
        height: 24px;
        margin-top: calc(-24px / 2);
        margin-left: calc(-24px / 2);
        border: 2px solid transparent;
        ${loaderColor};
    }
    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
`;
