import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";

import {isEmpty} from "@pg-mono/nodash";

import {IIssue} from "../api/get_issues";
import {psr} from "../styles/helpers";
import {DownloadButton} from "./common/commonStyledElements";
import {ElementLoader} from "./common/ElementLoader";
import {SectionTitle} from "./common/SectionTitle";
import {BasicSlider} from "./common/slider/Slider";

import backgroundWave from "../images/background_wave.png";

interface IProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
    issues: IIssue[] | null;
}
export const Archive = (props: IProps) => {
    const mobileBreakpoint = 1024;
    const [slidesCounter, setSlidesCounter] = useState(window.innerWidth < mobileBreakpoint ? 1 : 3);

    const sliderConfig = {
        infinite: true,
        slidesToShow: slidesCounter,
        slidesToScroll: 1
    };

    const resizeHandler = () => {
        if (window.innerWidth < mobileBreakpoint) {
            return setSlidesCounter(1);
        }

        return setSlidesCounter(3);
    };

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    const slides = (props.issues || []).map((item) => {
        return (
            <IssueHolder key={item.number}>
                <ImgWrapper>
                    <img src={item.cover_image} width="100%" height="auto" alt="archiwum" />
                    <DownloadButton href={item.file} target="_blank">
                        Pobierz
                    </DownloadButton>
                </ImgWrapper>

                <TextContainer>
                    <Title>Deweloper&Marketing</Title>
                    <Issue>{item.number}</Issue>
                </TextContainer>
            </IssueHolder>
        );
    });

    return (
        <ArchiveSection ref={props.refElem}>
            <SectionTitle title="Archiwum" lineColor="#fff" />

            <ContentContainer>
                {!isEmpty(props.issues) ? <Slider sliderConfig={sliderConfig}>{slides}</Slider> : <ElementLoader height="588px" width="100%" color="#fff" />}
            </ContentContainer>
        </ArchiveSection>
    );
};

const ArchiveSection = styled("section")`
    padding: 25px 0 20px;
    background-image: url("${backgroundWave}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: auto;
    margin: 30px 0 0 0;
    position: relative;
    color: #fff;

    @media screen and (min-width: 1024px) {
        margin-top: 75px;
    }
`;

export const ContentContainer = styled("div")`
    max-width: 1064px;
    max-height: 620px;
    margin: 0 auto;

    .left:not(.svg-icon) {
        position: absolute !important;
        left: 0 !important;
    }

    .slick-slide {
        display: flex !important;
        justify-content: center !important;
    }

    @media screen and (max-width: 1280px) {
        max-width: 800px;
    }
`;

const Slider = styled(BasicSlider)`
    height: 100%;
`;

const IssueHolder = styled("div")`
    ${psr};
    margin: 0 auto;
    height: 584px;
    width: 332px;

    @media screen and (max-width: 1280px) {
        width: 250px;
        height: 465px;
    }
`;

const ImgWrapper = styled("div")`
    position: relative;
    width: 332px;
    margin: 0 auto;

    @media screen and (max-width: 1280px) {
        width: 250px;
    }
`;

const TextContainer = styled("div")`
    text-align: center;
    margin-top: 30px;
`;

const Title = styled("p")`
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 45px;

    @media screen and (max-width: 1280px) {
        font-size: 20px;
        line-height: 30px;
    }
`;

const Issue = styled("p")`
    font-size: 25px;
    letter-spacing: 0.42px;
    line-height: 37px;
    text-transform: capitalize;

    @media screen and (max-width: 1280px) {
        font-size: 18px;
        line-height: 26px;
    }
`;
