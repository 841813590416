import React from "react";
import {css, keyframes} from "@emotion/react";
import styled from "@emotion/styled";
import cn from "classnames";

interface IProps {
    onClick: () => void;
    opened: boolean;
    color?: string;
    invertedTriggerColor?: string;
}

export const HamburgerTrigger = (props: IProps) => {
    const triggerClassName = cn(props.opened && "opened", "menu-trigger");

    return (
        <NavTriggerWrapper className={triggerClassName} onClick={props.onClick} color={props.color} invertedTriggerColor={props.invertedTriggerColor}>
            <div>
                <span />
                <span />
                <span />
            </div>
        </NavTriggerWrapper>
    );
};

const smallBig = keyframes`
  0%, 100%{
    transform: scale(1);
  }

  50%{
    transform: scale(0);
  }
`;

const customStyles = (props: {color?: string; invertedTriggerColor?: string}) => css`
    background-color: ${props.color};
`;

const customInvertedStyles = (props: {invertedTriggerColor?: string}) => css`
    background-color: ${props.invertedTriggerColor};
`;

export const NavTriggerWrapper = styled("div")`
    transition: all 0.3s ease-in-out;
    z-index: 130;
    padding: 10px;
    zoom: 1.5;

    &.opened {
        position: absolute;
        top: 10px;
        right: 2px;
    }

    @media screen and (min-width: 1025px) {
        display: none;
    }

    > div {
        position: relative;
        width: 19px;
        height: 14px;
    }

    span {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: white;
        transition: all 0.3s ease-in-out;
        border-radius: 4px;
        ${customStyles};

        &:nth-of-type(1) {
            top: 0;
            transform-origin: 50%;
        }

        &:nth-of-type(2) {
            top: 6px;
            right: 0;
        }

        &:nth-of-type(3) {
            top: 12px;
            right: 0;
            transform-origin: 50%;
        }
    }

    &.opened {
        animation: ${smallBig} 0.6s forwards;

        span {
            transition-delay: 0.2s;
            ${customInvertedStyles}

            &:nth-of-type(1) {
                transform: translateY(6px) rotate(45deg);
            }

            &:nth-of-type(2) {
                opacity: 0;
            }

            &:nth-of-type(3) {
                transform: translateY(-6px) rotate(-45deg);
                width: 100%;
            }
        }
    }
`;
