import {css} from "@emotion/react";

// Display
export const db = css`
    display: block;
`;
export const dib = css`
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
`;
export const dn = css`
    display: none;
`;

// Vertical Align
export const vat = css`
    vertical-align: top;
`;
export const vab = css`
    vertical-align: bottom;
`;
export const vam = css`
    vertical-align: middle;
`;

// Text decorations
export const tdn = css`
    text-decoration: none;
`;

export const tdu = css`
    text-decoration: underline;
`;

// Text aligns
export const tal = css`
    text-align: left;
`;
export const tac = css`
    text-align: center;
`;
export const tar = css`
    text-align: right;
`;

// Font weights
export const fwb = css`
    font-weight: 600;
`;
export const fwm = css`
    font-weight: 500;
`;
export const fwn = css`
    font-weight: 400;
`;
export const fwl = css`
    font-weight: 300;
`;

// Text transforms
export const ttc = css`
    text-transform: capitalize;
`;
export const ttu = css`
    text-transform: uppercase;
`;
export const ttl = css`
    text-transform: lowercase;
`;

// -------------------------------------------------------------------------- //

// Variables
const xxxs = "1px";
const xxs = "2px";
const xs = "3px";
const sm = "5px";
const md = "10px";
const lg = "15px";
const xl = "20px";
const xxl = "25px";
const xxxl = "30px";

// Margins
export const m_a = css`
    margin: auto;
`;
export const m_0 = css`
    margin: 0;
`;
export const m_0a = css`
    margin: 0 auto;
`;

export const m_xxs = css`margin: ${xxxs}};`;
export const m_xs = css`
    margin: ${xs};
`;
export const m_sm = css`
    margin: ${sm};
`;
export const m_md = css`
    margin: ${md};
`;
export const m_lg = css`
    margin: ${lg};
`;
export const m_xl = css`
    margin: ${xl};
`;
export const m_xxl = css`
    margin: ${xxl};
`;
export const m_xxxl = css`
    margin: ${xxxl};
`;

// Margins vertical
export const mv_0 = css`
    margin-top: 0;
    margin-bottom: 0;
`;
export const mv_xxs = css`
    margin-top: ${xxs};
    margin-bottom: ${xxs};
`;
export const mv_xs = css`
    margin-top: ${xs};
    margin-bottom: ${xs};
`;
export const mv_sm = css`
    margin-top: ${sm};
    margin-bottom: ${sm};
`;
export const mv_md = css`
    margin-top: ${md};
    margin-bottom: ${md};
`;
export const mv_lg = css`
    margin-top: ${lg};
    margin-bottom: ${lg};
`;
export const mv_xl = css`
    margin-top: ${xl};
    margin-bottom: ${xl};
`;
export const mv_xxl = css`
    margin-top: ${xxl};
    margin-bottom: ${xxl};
`;
export const mv_xxxl = css`
    margin-top: ${xxxl};
    margin-bottom: ${xxxl};
`;

// Margins horizontal
export const mh_0 = css`
    margin-left: 0;
    margin-right: 0;
`;
export const mh_a = css`
    margin-left: auto;
    margin-right: auto;
`;
export const mh_xxs = css`
    margin-left: ${xxs};
    margin-right: ${xxs};
`;
export const mh_xs = css`
    margin-left: ${xs};
    margin-right: ${xs};
`;
export const mh_sm = css`
    margin-left: ${sm};
    margin-right: ${sm};
`;
export const mh_md = css`
    margin-left: ${md};
    margin-right: ${md};
`;
export const mh_lg = css`
    margin-left: ${lg};
    margin-right: ${lg};
`;
export const mh_xl = css`
    margin-left: ${xl};
    margin-right: ${xl};
`;
export const mh_xxl = css`
    margin-left: ${xxl};
    margin-right: ${xxl};
`;
export const mh_xxxl = css`
    margin-left: ${xxxl};
    margin-right: ${xxxl};
`;

// Paddings
export const p_0 = css`
    padding: 0;
`;
export const p_xxs = css`
    padding: ${xxs};
`;
export const p_xs = css`
    padding: ${xs};
`;
export const p_sm = css`
    padding: ${sm};
`;
export const p_md = css`
    padding: ${md};
`;
export const p_lg = css`
    padding: ${lg};
`;
export const p_xl = css`
    padding: ${xl};
`;
export const p_xxl = css`
    padding: ${xxl};
`;
export const p_xxxl = css`
    padding: ${xxxl};
`;

// Paddings vertical
export const pv_0 = css`
    padding-top: 0;
    padding-bottom: 0;
`;
export const pv_xxxs = css`
    padding-top: ${xxxs};
    padding-bottom: ${xxxs};
`;
export const pv_xxs = css`
    padding-top: ${xxs};
    padding-bottom: ${xxs};
`;
export const pv_xs = css`
    padding-top: ${xs};
    padding-bottom: ${xs};
`;
export const pv_sm = css`
    padding-top: ${sm};
    padding-bottom: ${sm};
`;
export const pv_md = css`
    padding-top: ${md};
    padding-bottom: ${md};
`;
export const pv_lg = css`
    padding-top: ${lg};
    padding-bottom: ${lg};
`;
export const pv_xl = css`
    padding-top: ${xl};
    padding-bottom: ${xl};
`;
export const pv_xxl = css`
    padding-top: ${xxl};
    padding-bottom: ${xxl};
`;
export const pv_xxxl = css`
    padding-top: ${xxxl};
    padding-bottom: ${xxxl};
`;

// Paddings horizontal
export const ph_0 = css`
    padding-left: 0;
    padding-right: 0;
`;
export const ph_xxs = css`
    padding-left: ${xxs};
    padding-right: ${xxs};
`;
export const ph_xs = css`
    padding-left: ${xs};
    padding-right: ${xs};
`;
export const ph_sm = css`
    padding-left: ${sm};
    padding-right: ${sm};
`;
export const ph_md = css`
    padding-left: ${md};
    padding-right: ${md};
`;
export const ph_lg = css`
    padding-left: ${lg};
    padding-right: ${lg};
`;
export const ph_xl = css`
    padding-left: ${xl};
    padding-right: ${xl};
`;
export const ph_xxl = css`
    padding-left: ${xxl};
    padding-right: ${xxl};
`;
export const ph_xxxl = css`
    padding-left: ${xxxl};
    padding-right: ${xxxl};
`;

// -------------------------------------------------------------------------- //

// Positions
export const psa = css`
    position: absolute;
`;
export const psf = css`
    position: fixed;
`;
export const psr = css`
    position: relative;
`;
export const pss = css`
    position: sticky;
`;

export const vc = css`
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
`;
export const hc = css`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
`;
export const vhc = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const t0 = css`
    top: 0;
`;
export const r0 = css`
    right: 0;
`;
export const b0 = css`
    bottom: 0;
`;
export const l0 = css`
    left: 0;
`;

export const z0 = css`
    z-index: 0;
`;
export const z1 = css`
    z-index: 1;
`;
export const z2 = css`
    z-index: 2;
`;
export const zmax = css`
    z-index: 2147483647;
`;

// Backgrounds
export const bgc_white = css`
    background-color: #fff;
`;
export const bgc_black = css`
    background-color: #000;
`;

// Text color

//text color variables
const text_color = "#333";
const link_color = "#0084e0";

//fill function
const fill_color = (color: string) => css`
    fill: ${color};
`;

//color classes
export const c_text = css`
    color: ${text_color};
    ${fill_color(text_color)}
`;

export const c_link = css`
    color: ${link_color};
    ${fill_color(link_color)}
`;

export const c_white = css`
    color: #fff;
    ${fill_color("#fff")}
`;

export const c_black = css`
    color: #000;
    ${fill_color("#000")}
`;

export const c_primary = css`
    color: #0084e0;
    ${fill_color("#0084e0")}
`;

export const c_gray = css`
    color: #999;
    ${fill_color("#999")}
`;

export const c_gray_light = css`
    color: #bbb;
    ${fill_color("#bbb")}
`;

export const c_gray_lighter = css`
    color: #eee;
    ${fill_color("#eee")}
`;

// Font size
export const fs10 = css`
    font-size: 1rem;
`;
export const fs12 = css`
    font-size: 1.2rem;
`;
export const fs14 = css`
    font-size: 1.4rem;
`;
export const fs16 = css`
    font-size: 1.6rem;
`;
export const fs18 = css`
    font-size: 1.8rem;
`;
export const fs20 = css`
    font-size: 2rem;
`;
export const fs22 = css`
    font-size: 2.2rem;
`;
export const fs24 = css`
    font-size: 2.4rem;
`;
export const fs26 = css`
    font-size: 2.6rem;
`;
export const fs30 = css`
    font-size: 3rem;
`;

// Height
export const h0 = css`
    height: 0;
`;
export const ha = css`
    height: auto !important;
`;
export const h100 = css`
    height: 100%;
`;

// Width
export const wa = css`
    width: auto;
    flex-basis: auto;
`;
export const w10 = css`
    width: 10%;
    flex-basis: 10%;
`;
export const w20 = css`
    width: 20%;
    flex-basis: 20%;
`;
export const w30 = css`
    width: 30%;
    flex-basis: 30%;
`;
export const w33 = css`
    width: (100% / 3);
    flex-basis: (100% / 3);
`;
export const w40 = css`
    width: 40%;
    flex-basis: 40%;
`;
export const w50 = css`
    width: 50%;
    flex-basis: 50%;
`;
export const w60 = css`
    width: 60%;
    flex-basis: 60%;
`;
export const w66 = css`
    width: (100% / 1.5);
    flex-basis: (100% / 1.5);
`;
export const w70 = css`
    width: 70%;
    flex-basis: 70%;
`;
export const w80 = css`
    width: 80%;
    flex-basis: 80%;
`;
export const w90 = css`
    width: 90%;
    flex-basis: 90%;
`;
export const w100 = css`
    width: 100%;
    flex-basis: 100%;
`;

// Lists
export const lsn = css`
    list-style: none;
`;
export const mw100 = css`
    max-width: 100%;
`;
